import React, { Dispatch } from "react"
import { UserActions } from "../utils/userReducer"
import { SelectionActions } from "../utils/selectionReducer"
import { UserState, SelectionState } from "../types"
import AddressInput from "./AddressInput"
import RepCards from "./RepCards"

interface Props {
  userDispatch: Dispatch<UserActions>
  userState: UserState
  selectionDispatch: Dispatch<SelectionActions>
  selectionState: SelectionState
}

const RepSelection = ({
  userDispatch,
  userState,
  selectionState,
  selectionDispatch,
}: Props) => {
  return (
    <div>
      <AddressInput
        userDispatch={userDispatch}
        selectionDispatch={selectionDispatch}
      />
      {userState.reps && (
        <RepCards
          reps={userState.reps}
          selectionDispatch={selectionDispatch}
          selectionState={selectionState}
        />
      )}
    </div>
  )
}

export default RepSelection
