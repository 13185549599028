import React, { Dispatch, useState, useEffect } from "react"
import ImageGallery from "react-image-gallery"
import Img from "gatsby-image"
import { ImageType } from "../types"
import { SelectionActions } from "../utils/selectionReducer"
// @ts-ignore
import logo from "../images/instagram_logo.png"

interface Props {
  dispatch: Dispatch<SelectionActions>
  images: ImageType[]
}

const PostcardSelection = ({ dispatch, images }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    dispatch({
      type: "SELECT_IMG",
      postcardUrl: `https:${images[0].postcardImg.file.url}`,
    })
  }, [])

  const items = images.map((i, index) => {
    return {
      thumbnail: images[index].postcardImg.file.url,
      original: images[index].postcardImg.file.url,
      // TODO: get <Img> to render and fit property
      // renderItem: () => <Img fluid={images[index].webImg.fluid} />,
    }
  })

  const handleSlide = (index: number) => {
    setCurrentIndex(index)
    dispatch({
      type: "SELECT_IMG",
      postcardUrl: `https:${images[index].postcardImg.file.url}`,
    })
  }

  return (
    <section id="postcard-selection">
      <h3>Select an Image for your Postcard</h3>
      <ImageGallery
        items={items}
        showPlayButton={false}
        showFullscreenButton={false}
        onSlide={handleSlide}
      />
      <div className="credit">
        <a
          href={images[currentIndex].artistUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Design by {images[currentIndex].artist}</p>
        </a>
        {images[currentIndex].artistInstagram && (
          <a
            href={images[currentIndex].artistInstagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logo} alt="instagram logo" />
          </a>
        )}
      </div>
    </section>
  )
}

export default PostcardSelection
