import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Rep } from "../types"
import { faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faTwitter,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import find from "lodash/find"

interface Props {
  reps: Rep[]
}

const CallAndEmail = ({ reps }: Props) => {
  const [webIsOpen, setWebIsOpen] = useState(false)
  const [phoneIsOpen, setPhoneIsOpen] = useState(true)
  const [twitterIsOpen, setTwitterIsOpen] = useState(false)
  const [faIsOpen, setFaIsOpen] = useState(false)
  const [youtubeIsOpen, setYoutubeIsOpen] = useState(false)

  const clickWeb = () => {
    setWebIsOpen(!webIsOpen)
    setTwitterIsOpen(false)
    setPhoneIsOpen(false)
    setFaIsOpen(false)
    setYoutubeIsOpen(false)
  }
  const clickPhone = () => {
    setWebIsOpen(false)
    setPhoneIsOpen(!phoneIsOpen)
    setTwitterIsOpen(false)
    setFaIsOpen(false)
    setYoutubeIsOpen(false)
  }
  const clickTwitter = () => {
    setWebIsOpen(false)
    setPhoneIsOpen(false)
    setTwitterIsOpen(!twitterIsOpen)
    setFaIsOpen(false)
    setYoutubeIsOpen(false)
  }
  const clickFacebook = () => {
    setWebIsOpen(false)
    setPhoneIsOpen(false)
    setTwitterIsOpen(false)
    setFaIsOpen(!faIsOpen)
    setYoutubeIsOpen(false)
  }
  const clickYoutube = () => {
    setWebIsOpen(false)
    setPhoneIsOpen(false)
    setTwitterIsOpen(false)
    setFaIsOpen(false)
    setYoutubeIsOpen(!youtubeIsOpen)
  }

  const channel = (r: Rep, type: string): string => {
    const id = find(r.channels, o => o.type === type)?.id || ""
    if (type === "Twitter") {
      return `https://twitter.com/${id}`
    }
    if (type === "YouTube") {
      return `https://youtube.com/user/${id}`
    }
    if (type === "Facebook") {
      return `https://facebook.com/${id}`
    }
    return ""
  }

  return (
    <div id="call-email">
      <h3>Contact your Reps by Phone or on the Web</h3>
      <p>Click icons to show contact information</p>
      <div className="icon-wrapper">
        <div onClick={clickPhone} className={phoneIsOpen ? "border" : ""}>
          <FontAwesomeIcon
            data-testid="phone-icon"
            icon={faPhone}
            size="3x"
            color="#3dbccf"
          />
        </div>
        <div onClick={clickTwitter} className={twitterIsOpen ? "border" : ""}>
          <FontAwesomeIcon
            data-testid="twitter-icon"
            icon={faTwitter}
            size="3x"
            color="#3dbccf"
          />
        </div>
        <div onClick={clickFacebook} className={faIsOpen ? "border" : ""}>
          <FontAwesomeIcon icon={faFacebook} size="3x" color="#3dbccf" />
        </div>
        <div onClick={clickWeb} className={webIsOpen ? "border" : ""}>
          <FontAwesomeIcon icon={faGlobe} size="3x" color="#3dbccf" />
        </div>
      </div>
      <div className="contacts">
        {phoneIsOpen && (
          <ul>
            {reps.map((r, i) => (
              <li key={i}>
                {r.titleName}{" "}
                <a
                  href={`tel:${r.phones?.[0] || "n/a"}`}
                  data-testid="phone-link"
                >
                  {r.phones?.[0]}
                </a>
              </li>
            ))}
          </ul>
        )}
        {webIsOpen && (
          <ul>
            {reps &&
              reps.map((r, i) => (
                <li key={i}>
                  {r.titleName}{" "}
                  <a
                    href={`${r.urls?.[0] || "n/a"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {r.urls?.[0] || "n/a"}
                  </a>
                </li>
              ))}{" "}
          </ul>
        )}
        {twitterIsOpen && (
          <ul>
            {reps &&
              reps.map((r, i) => (
                <li key={i} data-testid="twitter-link">
                  {r.titleName}{" "}
                  <a
                    href={`${channel(r, "Twitter")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {channel(r, "Twitter")}
                  </a>
                </li>
              ))}{" "}
          </ul>
        )}
        {faIsOpen && (
          <ul>
            {reps &&
              reps.map((r, i) => (
                <li key={i}>
                  {r.titleName}{" "}
                  <a
                    href={`${channel(r, "Facebook")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {channel(r, "Facebook")}
                  </a>
                </li>
              ))}{" "}
          </ul>
        )}
      </div>
    </div>
  )
}

export default CallAndEmail
