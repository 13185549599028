import { initialUserState } from "./initialState"
import { UserState, UserReps, UserDetails, Rep, Address } from "../types"

export type UserActionsTypes =
  | "USER_RESET"
  | "SET_USER_ADDRESS"
  | "SET_USER_REPS"
  | "SET_USER_DETAILS"

export type UserFieldKeys = "firstName" | "lastName" | "email"

export type UserActions =
  | {
      type: "USER_RESET"
    }
  | {
      type: "SET_USER_ADDRESS"
      address: Address
    }
  | {
      type: "SET_USER_REPS"
      reps: UserReps
    }
  | {
      type: "SET_USER_DETAILS"
      userDetails: UserDetails
    }

const userReducer = (
  state: UserState = initialUserState,
  action: UserActions
): UserState => {
  switch (action.type) {
    case "USER_RESET":
      return initialUserState
    case "SET_USER_ADDRESS":
      return { ...state, address: action.address }
    case "SET_USER_REPS":
      const { reps } = action
      return { ...state, reps }
    case "SET_USER_DETAILS":
      const d = action.userDetails
      return {
        ...state,
        email: d.email,
        firstName: d.firstName,
        lastName: d.lastName,
      }
    default:
      throw new Error("unknown action")
  }
}

export default userReducer
