import React, { Dispatch } from "react"
import find from "lodash/find"
import { useEffect } from "react"
import { UserReps, SelectionState } from "../types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons"
import { UserActions } from "../utils/userReducer"
import { SelectionActions } from "../utils/selectionReducer"
import { Rep } from "../types"

interface Props {
  reps: UserReps
  selectionState: SelectionState
  selectionDispatch: Dispatch<SelectionActions>
}

const RepCards = ({ reps, selectionDispatch, selectionState }: Props) => {
  const isGLSEN = selectionState.campaign?.campaignName.includes("GLSEN")
  const isGLSEN2023 = selectionState.campaign?.slug === "GLSEN2023"

  const onRepClick = (i: number): void => {
    let selectedRep: Rep | null = null
    if (i === 2) {
      selectedRep = reps.house
    }
    if (i === 0 || i === 1) {
      selectedRep = reps.senate[i]
    }
    if (i === 3 || i === 4) {
      selectedRep = reps.president[i - 3]
    }
    if (i === 10) {
      selectedRep = reps.governor
    }
    if (i === 11) {
      selectedRep = reps.stateHouse
    }
    if (i === 12) {
      selectedRep = reps.stateSenate
    }
    if (selectedRep?.name === "VACANT") {
      return // fix for missing info
    }
    if (selectedRep && selectedRep.address?.[0]?.line1.length < 8 && i <= 2) {
      selectedRep.address[0].line1 =
        "US Capitol " + selectedRep.address[0].line1
    }
    if (selectedRep) {
      selectionDispatch({ type: "SELECT_REP", rep: selectedRep })
    }
  }

  const isSelected = (repName: string): boolean => {
    return !!find(selectionState.reps, o => o.name === repName)
  }

  const house = reps.house || null
  const { governor, stateHouse, stateSenate } = reps

  console.log("reps", reps)
  const RepCard = ({ rep, index }: { rep: Rep | null; index: number }) => {
    if (!rep) return <></>
    return (
      <div>
        <div
          key={rep.name}
          onClick={() => onRepClick(index)}
          className="rep-card"
        >
          <div className="left">
            <div>
              <div className="rep-name-party">
                <h4>{rep.name}</h4>
                <p className="lead">{rep.party}</p>
              </div>
              <p className="bold">
                {rep.address?.[0]
                  ? `${rep.address?.[0].line1} ${rep.address?.[0]?.city}`
                  : ""}
              </p>
            </div>
          </div>
          <div className="right">
            {isSelected(rep.name) ? (
              <div className="checked">
                <FontAwesomeIcon
                  className="checked"
                  icon={faCheck}
                  size="2x"
                  color="green"
                />
                <p>$2</p>
              </div>
            ) : (
              <div className="unchecked">
                <FontAwesomeIcon
                  className="unchecked"
                  icon={faPlus}
                  size="2x"
                  color="grey"
                />
                <p>$2</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const hasAddress = (rep: Rep | null) => (rep?.address?.[0] ? true : false)

  const { campaign } = selectionState
  return (
    <div id="rep-list-selection">
      <h3>Select your Representatives</h3>
      {campaign?.sendToFedRepOption && (
        <>
          {reps.senate.length > 0 && <h4>Your Senators:</h4>}
          {reps.senate.map((r, i) => {
            if (hasAddress(r)) return <RepCard rep={r} index={i} key={i} />
          })}
          <br />
          {hasAddress(house) && (
            <div>
              <h4>Your House Rep:</h4>
              <RepCard rep={house} index={2} key={2} />
            </div>
          )}
          <br />
        </>
      )}
      {campaign?.sendToPresidentOption && (
        <>
          <h4>Your President & Vice President:</h4>
          {reps.president.map((r, i) => {
            return <RepCard rep={r} index={3 + i} key={i} />
          })}
          <br />
        </>
      )}
      {campaign?.sendToStateRepOption && (
        <>
          {hasAddress(governor) && (
            <>
              <h4>Your Governor:</h4>
              <RepCard rep={governor} index={10} />
              <br />
            </>
          )}
          {hasAddress(stateHouse) && (
            <>
              <h4>Your {stateHouse?.title ?? "State Assembly Rep"}:</h4>
              <RepCard rep={stateHouse} index={11} />
              <br />
            </>
          )}
          {hasAddress(stateSenate) && (
            <>
              <h4>Your {stateSenate?.title ?? "State Senator"}:</h4>
              <RepCard rep={stateSenate} index={12} />
              <br />
            </>
          )}
        </>
      )}
      <br />
      {selectionState.reps.length > 0 && !isGLSEN2023 && (
        <div className="total-cost">
          <p className="lead">Total: {selectionState.reps.length * 2}$</p>
          <p className="lead">({selectionState.reps.length}$ donated)</p>
        </div>
      )}
    </div>
  )
}

export default RepCards
