import axios from "axios"
import find from "lodash/find"
import { geocodeByAddress } from "react-places-autocomplete"
import { Address, Rep, RepApiCall, UserReps } from "../types"
import { reportError, reportMessage } from "./index"
import { useReducer } from "react"
import userReducer from "./userReducer"
import { initialUserState } from "./initialState"
import governorAddress from "../../governorData.json"
import stateHouseAddress from "../../stateHouseData.json"
import stateSenateAddress from "../../stateSenateData.json"

const url = "https://www.googleapis.com/civicinfo/v2"

export const getAdditionalHouseAddressInfo = async (
  member: Rep
): Promise<Address[]> => {
  try {
    const allRepsInfo = await fetch(
      "https://theunitedstates.io/congress-legislators/legislators-current.json"
    ).then(async res => await res.json())
    let m = find(allRepsInfo, o => o.name.official_full === member.name)
    const currentTerm = m.terms[m.terms.length - 1]
    const address = await geocodeByAddress(currentTerm.address)
    const a = address[0].address_components
    const getProperty = type => {
      // TODO: error handing
      let res = find(a, o => o.types.includes(type))
      return (res && res.short_name) || null
    }
    const getTown = () => {
      let town = getProperty("locality")
      if (!town) {
        town = getProperty("sublocality")
      }
      return town
    }
    const newAddress = {
      line1: "United States House of Representatives",
      line2: currentTerm.address.split(";")[0],
      city: getTown(),
      state: getProperty("administrative_area_level_1"),
      zip: getProperty("postal_code"),
    }
    return [newAddress]
  } catch (err) {
    reportMessage(`fallback to default address for ${member.name}`)
    // Fallback to one of the addresses in case something went wrong
    return [
      {
        line1: "322 Cannon House Office Building",
        city: "Washington",
        state: "DC",
        zip: "20515",
      },
    ]
  }
}

// TODO: check for D.C. Puerto Rico etc
const sanitizeRepInfo = async ({
  house,
  senate,
  governor,
  stateHouse,
  stateSenate,
  userState,
}: {
  house: Rep | null
  senate?: Rep[]
  governor: Rep | null
  stateHouse: Rep | null
  stateSenate: Rep | null
  userState: string
}): Promise<UserReps> => {
  const fixTitleName = (rep: Rep | null) => {
    if (!rep) return null
    return { ...rep, titleName: `${rep.title} ${rep.name}` }
  }
  const fixGovernor = (rep: Rep | null) => {
    if (!rep || rep.address) return rep
    console.log("FALLBACK GOVERNOR")
    return { ...rep, address: [governorAddress[userState]] }
  }
  const fixStateHouse = (rep: Rep | null) => {
    if (!rep || rep.address) return rep
    console.log("FALLBACK STATE HOUSE")
    return { ...rep, address: [stateHouseAddress[userState]] }
  }
  const fixStateSenate = (rep: Rep | null) => {
    if (!rep || rep.address) return rep
    console.log("FALLBACK STATE SENATE")
    return { ...rep, address: [stateSenateAddress[userState]] }
  }
  const getTitleName = (fullName: string): string => {
    let endOfName: string = fullName.split(" ").pop() || ""
    if (
      endOfName === "Jr." ||
      endOfName === "III" ||
      endOfName === "IV" ||
      endOfName === "Sr."
    ) {
      endOfName = fullName.split(" ")[endOfName.length - 1]
    }
    return endOfName
  }

  const senateWithChamber =
    senate?.map((s, i) => ({
      ...s,
      chamber: `senate${i}`,
      titleName: `Senator ${getTitleName(s.name)}`,
      address: s.address ?? [
        {
          line1: "United States Senate",
          city: "Washington",
          state: "DC",
          zip: 20510,
        },
      ],
    })) || []

  const houseMember = async (): Promise<Rep | null> => {
    if (house && !house.address) {
      house.address = await getAdditionalHouseAddressInfo(house)[0]
    }
    return house && house.name
      ? {
          ...house,
          chamber: "house",
          titleName: `Representative ${getTitleName(house.name)}`,
        }
      : null
  }
  const governorWithAddress = fixTitleName(fixGovernor(governor))
  const stateHouseWithAddress = fixTitleName(fixStateHouse(stateHouse))
  const stateSenateWithAddress = fixTitleName(fixStateSenate(stateSenate))
  let reps: UserReps = {
    senate: senateWithChamber,
    house: await houseMember(),
    governor: governorWithAddress,
    stateHouse: stateHouseWithAddress,
    stateSenate: stateSenateWithAddress,
    president: [
      {
        address: [
          {
            line1: "The White House",
            line2: "1600 Pennsylvania Ave NW",
            city: "Washington",
            state: "DC",
            zip: "20500",
          },
        ],
        channels: [],
        name: "Joe Biden",
        party: "Democrat",
        phones: [""],
        photoUrl: "",
        urls: [],
        chamber: "president",
        titleName: "President Joe Biden",
        title: "President",
      },
      {
        address: [
          {
            line1: "The White House",
            line2: "1600 Pennsylvania Ave NW",
            city: "Washington",
            state: "DC",
            zip: "20500",
          },
        ],
        channels: [],
        name: "Kamala Harris",
        party: "Democrat",
        phones: [""],
        photoUrl: "",
        urls: [],
        chamber: "president",
        titleName: "Vice President Kamala Harris",
        title: "Vice President",
      },
    ],
  }
  return reps
}

const getReps = async (
  address: string,
  userState: string
): Promise<UserReps | null> => {
  try {
    let house = await axios
      .get<RepApiCall>(
        `${url}/representatives?address=${address}&levels=country&roles=legislatorLowerBody&key=${process.env.GATSBY_GOOGLE_MAPS_API}`
      )
      .catch(err => {
        reportError(err)
        reportMessage(`error fetching house rep for ${address}`)
      })
    let senate = await axios
      .get<RepApiCall>(
        `${url}/representatives?address=${address}&levels=country&roles=legislatorUpperBody&key=${process.env.GATSBY_GOOGLE_MAPS_API}`
      )
      .catch(err => {
        reportError(err)
        reportMessage(`error fetching senator for ${address}`)
      })
    let governorData = await axios
      .get<RepApiCall>(
        `${url}/representatives?address=${address}&levels=administrativeArea1&roles=headOfGovernment&key=${process.env.GATSBY_GOOGLE_MAPS_API}`
      )
      .catch(err => {
        reportError(err)
        reportMessage(`error fetching governor for ${address}`)
      })
    let stateHouseData = await axios
      .get<RepApiCall>(
        `${url}/representatives?address=${address}&levels=administrativeArea1&roles=legislatorLowerBody&key=${process.env.GATSBY_GOOGLE_MAPS_API}`
      )
      .catch(err => {
        reportError(err)
        reportMessage(`error fetching stateRep for ${address}`)
      })
    let stateSenateData = await axios
      .get<RepApiCall>(
        `${url}/representatives?address=${address}&levels=administrativeArea1&roles=legislatorUpperBody&key=${process.env.GATSBY_GOOGLE_MAPS_API}`
      )
      .catch(err => {
        reportError(err)
        reportMessage(`error fetching stateSenator for ${address}`)
      })
    let houseMember = house?.data?.officials[0]
      ? {
          ...house?.data?.officials[0],
          title: house?.data?.offices[0]?.name ?? "",
        }
      : null
    let senateMembers = (senate && senate?.data?.officials) || []
    senateMembers = senateMembers.map(rep => ({
      ...rep,
      title: senate?.data?.offices[0].name ?? "",
    }))
    let governor =
      governorData && governorData.data?.officials[0]
        ? {
            ...governorData.data?.officials[0],
            title: governorData.data?.offices[0]?.name ?? "",
          }
        : null
    let stateHouse = stateHouseData?.data?.officials[0]
      ? {
          ...stateHouseData?.data?.officials[0],
          title: stateHouseData?.data.offices[0]?.name ?? "",
        }
      : null
    let stateSenate = stateSenateData?.data?.officials[0]
      ? {
          ...stateSenateData?.data?.officials[0],
          title: stateSenateData?.data?.offices[0]?.name ?? "",
        }
      : null
    return await sanitizeRepInfo({
      house: houseMember,
      senate: senateMembers,
      governor,
      stateHouse,
      stateSenate,
      userState,
    })
  } catch (e) {
    reportMessage(`no reps for ${address}`)
    return null
  }
}

export default getReps
