import { UserState, SelectionState } from "../types"

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const checkIfStepIsValid = (
  step: number,
  userState: UserState,
  selectionState: SelectionState
): boolean => {
  switch (step) {
    case 0:
      if (selectionState.postcardUrl) return true
      break
    case 1:
      if (userState.address && selectionState.reps.length > 0) return true
      break
    case 2:
      if (
        userState.firstName &&
        userState.lastName &&
        userState.email &&
        emailIsValid(userState.email) &&
        selectionState.text
      )
        return true
    default:
      return false
  }
  return false
}

export default checkIfStepIsValid
