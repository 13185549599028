import React, { Dispatch, useState, useEffect, useRef } from "react"
import { SelectionActions } from "../utils/selectionReducer"
import { UserState, SelectionState } from "../types"
// TODO: import graphql way
// @ts-ignore
import envelopeImg from "../images/flying-envelope.png"

interface Props {
  selectionDispatch: Dispatch<SelectionActions>
  selectionState: SelectionState
}

const totalChar = 499

const PostcardTextInput = ({ selectionDispatch, selectionState }: Props) => {
  const targetRef = useRef()
  const [height, setHeight] = useState(0)
  // const [cardText, setCardText] = useState(props.sampleText);
  const [cardText, setCardText] = useState("")
  // const [charLeft, setCharLeft] = useState(totalChar - props.sampleText.length);
  const [charLeft, setCharLeft] = useState(0)
  const isGLSEN2023 = selectionState.campaign?.slug === "GLSEN2023"

  useEffect(() => {
    // @ts-ignore
    targetRef.current && setHeight(targetRef?.current?.clientHeight)
    const text =
      selectionState.text ||
      selectionState.campaign?.sampleText.sampleText ||
      ""
    selectionDispatch({ type: "SELECT_SET_TEXT", text })
    setCardText(text)
    setCharLeft(totalChar - text.length)
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value
    if (totalChar - text.length >= 0) {
      setCardText(text)
      setCharLeft(totalChar - text.length)
      selectionDispatch({ type: "SELECT_SET_TEXT", text })
    }
  }

  return (
    <div id="postcard-text-input">
      <div className="card-left">
        <div className="card-top">
          <h4 className="grey">Dear [representative name],</h4>
          {!isGLSEN2023 && (
            <p className="grey text-align-right">
              {charLeft} characters remaining
            </p>
          )}
        </div>
        <div ref={targetRef} className="center-div">
          <div id="message-input">
            {isGLSEN2023 ? (
              <GLSEN2023_Text />
            ) : (
              <textarea
                style={{ height }}
                onChange={handleChange}
                value={cardText}
                placeholder="your custom message goes here"
              />
            )}
          </div>
        </div>
        <div className="card-bottom">
          <h4 className="grey">Sincerely,</h4>
          {/* <p>
            [your name here]
            </p> */}
        </div>
      </div>
      <div className="card-right">
        <img src={envelopeImg} alt="envelope logo" />
      </div>
    </div>
  )
}

export default PostcardTextInput

const GLSEN2023_Text = () => (
  <div>
    <p>
      In honor of the over 2 million LGBTQ+ youth who exist in the United
      States;
    </p>
    <p>
      I <b>pledge</b> to advocate for safe learning environments where young
      people, teachers, and school staff are free from the violence of racism,
      transphobia, homophobia, sexism, ableism, and all forms of systemic
      oppression.
    </p>
    <p>
      I <b>pledge</b> to advocate for LGBTQ+ affirming books, resources, and
      curriculum in schools.
    </p>
    <p>
      I <b>pledge</b> to rise up against hateful anti-LGBTQ+ bills and rhetoric.
    </p>
    <p>Will you join me in protecting LGBTQ+ youth?</p>
  </div>
)
