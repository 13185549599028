import React from "react"
import PaypalExpressBtn from "gatsby-paypal-button"
import { PaypalPayment } from "../types"
import { isDevEnv } from "../utils"

interface Props {
  amount: number
  onPayment: (paymentInfo: PaypalPayment | null) => any
  onPaymentError: (error: any) => any
}

const PaymentSection = ({ amount, onPayment, onPaymentError }: Props) => {
  const ENV = isDevEnv ? "sandbox" : "production"

  const onSuccess = (payment: PaypalPayment) => {
    onPayment(payment)
  }

  const onError = error => {
    console.log("error ", error)
    onPaymentError(error)
  }

  const onCancel = (data: any) => {
    console.log("cancelled", data)
    onPayment(null)
  }

  const CLIENT = {
    sandbox: process.env.GATSBY_PAYPAL_SANDBOX,
    production: process.env.GATSBY_PAYPAL_PRODUCTION,
  }

  return (
    <div id="paypal-express-btn-section">
      <PaypalExpressBtn
        env={ENV}
        client={CLIENT}
        currency={"USD"}
        total={amount}
        onSuccess={onSuccess}
        onError={onError}
        onCancel={onCancel}
        style={{
          size: "medium",
        }}
      />
    </div>
  )
}

export default PaymentSection
