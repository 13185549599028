import React from "react"
import { useLocation } from "@reach/router"
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share"
import { DOMAIN_URL } from "../constants"
import { SelectionState } from "../types"

interface Props {}

const SocialShare = ({}: Props) => {
  const location = useLocation()
  const isGLSEN2023 = location.pathname.includes("GLSEN2023")
  const pathname = isGLSEN2023 ? "/campaign/GLSEN" : location.pathname
  const twitterText = `I'm using @congresscards to send postcards to my reps in congress and raise funds for the causes I care about!`
  const fbText = `I'm using Congress.Cards to send postcards to my reps in congress and raise funds for the causes I care about!`
  const twitterGLSEN2023 =
    "I'm using @congresscards to send postcards to my reps and ask them to take the @GLSEN pledge to #RiseUp4LGBTQ"
  const fbGLSEN2023 =
    "I'm using Congress.Cards to send postcards to my reps and ask them to take the @GLSEN pledge to #RiseUp4LGBTQ"
  return (
    <div className="social-share">
      <FacebookShareButton
        url={`${DOMAIN_URL}${pathname}`}
        quote={isGLSEN2023 ? fbGLSEN2023 : fbText}
        style={{ marginRight: "2rem" }}
      >
        <FacebookIcon size={48} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={`${DOMAIN_URL}${pathname}`}
        title={isGLSEN2023 ? twitterGLSEN2023 : twitterText}
        style={{ marginRight: "1rem" }}
      >
        <TwitterIcon size={48} round />
      </TwitterShareButton>
      <p>
        Share on Facebook and Twitter and let your friends know about
        Congress.Cards
      </p>
    </div>
  )
}

export default SocialShare
