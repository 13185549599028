import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  ReactElement,
} from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import * as sentry from "@sentry/browser"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Campaign, CampaignCounts } from "../types"
import { apiClient, isDevEnv, reportMessage } from "../utils"
import RepSelection from "./RepSelection"
import PostcardSelection from "./PostcardSelection"
import MessageSelection from "./MessageSelection"
import StepNavigation from "./StepNavigation"
import SocialShare from "./SocialShare"
import Payment from "./Payment"
import userReducer from "../utils/userReducer"
import selectionReducer from "../utils/selectionReducer"
import checkIfStepIsValid from "../utils/checkIfStepIsValid"
import { initialUserState, initialSelectionState } from "../utils/initialState"
import { LoadScript } from "@react-google-maps/api"

const Nothing = () => null

interface Props {
  data: {
    contentfulCampaign: Campaign
  }
}

export default function CampaignTemplate({ data }: Props): ReactElement {
  const campaign = data.contentfulCampaign
  const [campaignCount, setCampaignCount] = useState<number>(0)
  const [step, setStep] = useState<number>(0)
  const [counts, setCounts] = useState<CampaignCounts | null>(null)

  const fetchCounts = async () => {
    try {
      const { data } = await apiClient.get<CampaignCounts>("getCounts")
      setCounts(data)
    } catch (e) {
      reportMessage("fetching campaign counts failed")
    }
  }

  const [userState, userDispatch] = useReducer(userReducer, initialUserState)
  const [selectionState, selectionDispatch] = useReducer(
    selectionReducer,
    initialSelectionState
  )
  const wizardFormRef = useRef()
  const isGLSEN = campaign.slug.includes("GLSEN")
  const isGLSEN2023 = campaign.slug === "GLSEN2023"

  if ((counts?.GLSEN2023 ?? 0) > 2250 && isGLSEN2023) {
    navigate("/campaign/GLSEN")
  }

  useEffect(() => {
    fetchCounts()
    if (isGLSEN) {
      setStep(1)
    }
    selectionDispatch({ type: "SELECT_CAMPAIGN", campaign })
  }, [campaign.slug])

  const stepIsValid = checkIfStepIsValid(step, userState, selectionState)

  useEffect(() => {
    if (wizardFormRef && wizardFormRef.current && step > 0) {
      if (step === 1 && isGLSEN) return
      // @ts-ignore
      wizardFormRef.current.scrollIntoView({
        behavior: "smooth",
        alignTop: true,
      })
    }
    if (step === 3) {
      sentry.setContext("user", userState)
    }
  }, [step])

  if (isDevEnv) {
    console.group("state")
    console.log("selectionState", selectionState)
    console.log("userState", userState)
    console.groupEnd()
  }

  return (
    <Layout>
      <SEO
        title={campaign.campaignName}
        image={campaign.campaignImg.image?.file.url}
      />
      <LoadScript
        id="script-loader"
        googleMapsApiKey={`${process.env.GATSBY_GOOGLE_MAPS_API}&libraries=places`}
        loadingElement={<Nothing />}
      ></LoadScript>
      <div id="campaign-header">
        <div className="content">
          <div>
            <h1>{campaign.campaignName}</h1>
            <h3>{campaign.summary.summary}</h3>
          </div>
        </div>
        <div className="image">
          <Img
            fluid={campaign.campaignImg.image?.fluid}
            alt={`${campaign.campaignName} logo`}
          />
        </div>
        {!isGLSEN && (
          <div className="disclaimer">
            <p>
              Congress.Cards has no direct affiliation with {campaign.charity},
              but you can learn more about their awesome work at{" "}
              <a href={campaign.charityUrl} target="_blank">
                {campaign.charityUrl}
              </a>
            </p>
          </div>
        )}
      </div>
      <section className="grey-bg-section border-top">
        <div id="wizard-form" ref={wizardFormRef}>
          {step === 0 && (
            <PostcardSelection
              images={campaign.postcardImg}
              dispatch={selectionDispatch}
            />
          )}
          {step === 1 && (
            <RepSelection
              selectionState={selectionState}
              selectionDispatch={selectionDispatch}
              userState={userState}
              userDispatch={userDispatch}
            />
          )}
          {step === 2 && (
            <MessageSelection
              selectionState={selectionState}
              selectionDispatch={selectionDispatch}
              userState={userState}
              userDispatch={userDispatch}
            />
          )}
          {step === 3 && (
            <Payment
              selectionState={selectionState}
              selectionDispatch={selectionDispatch}
              userState={userState}
              userDispatch={userDispatch}
            />
          )}
          <StepNavigation
            step={step}
            setStep={setStep}
            stepIsValid={stepIsValid}
            allDone={selectionState.allDone}
          />
        </div>
        <SocialShare />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Campaign($slug: String) {
    contentfulCampaign(slug: { eq: $slug }) {
      ...Basic
    }
  }
`
