import React, { Dispatch, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import { validateAddress } from "../utils/index"
import { Address, UserReps, SelectionState } from "../types"
import { UserActions } from "../utils/userReducer"
import { SelectionActions } from "../utils/selectionReducer"
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete"
import getReps from "../utils/getReps"

interface Props {
  userDispatch: Dispatch<UserActions>
  selectionDispatch: Dispatch<SelectionActions>
}

const AddressInput = ({ userDispatch, selectionDispatch }: Props) => {
  const [address, setAddress] = useState<string>("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const dispatchAddress = async (address: string): Promise<void> => {
    const results = await geocodeByAddress(address)
    const userAddress: Address | null = validateAddress(results)
    if (userAddress) {
      userDispatch({ type: "SET_USER_ADDRESS", address: userAddress })
      const reps: UserReps | null = await getReps(address, userAddress.state)
      if (reps) {
        userDispatch({ type: "SET_USER_REPS", reps })
      } else {
        setError("Something went wrong fetching your Representatives")
      }
    } else {
      setError("Please enter valid address with a street name, no PO Boxes")
    }
    setLoading(false)
  }

  useEffect(() => {
    const prevAddress = window?.localStorage.getItem("address") || null
    if (prevAddress) {
      setLoading(true)
      dispatchAddress(prevAddress)
      setAddress(prevAddress)
    }
  }, [])

  const handleSelect = async address => {
    setError("")
    setAddress(address)
    window.localStorage.setItem("address", address)
    setLoading(true)
    await dispatchAddress(address)
    selectionDispatch({ type: "RESET_REPS" })
  }

  return (
    <div id="address-input">
      <h3>Enter your Address</h3>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        autocomplete=""
        place
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Your residence...",
                className: "location-search-input",
              })}
              value={address}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item"
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <div className="loading">
        {loading && <FontAwesomeIcon icon={faSpinner} spin size="3x" />}
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  )
}

export default AddressInput
