import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CampaignList from "./CampaignList"
import { sanitize, byPriority } from "../utils"

interface Props {
  currentCampaign: string
}

const OtherCampaigns = ({ currentCampaign }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCampaign(filter: { active: { eq: true } }) {
        edges {
          node {
            ...Basic
          }
        }
      }
    }
  `)
  const campaigns = byPriority(sanitize("allContentfulCampaign", data)).filter(
    c => c.campaignName !== currentCampaign
  )
  return (
    <div>
      <h3 className="pb-0">Take part in one of our other Campaigns</h3>
      <CampaignList campaigns={campaigns} />
    </div>
  )
}

export default OtherCampaigns
