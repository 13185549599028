import React, { Dispatch } from "react"
import PostcardTextInput from "./PostcardTextInput"
import UserDetailInput from "./UserDetailInput"
import { UserActions } from "../utils/userReducer"
import { SelectionActions } from "../utils/selectionReducer"
import { UserState, SelectionState } from "../types"

interface Props {
  userDispatch: Dispatch<UserActions>
  userState: UserState
  selectionDispatch: Dispatch<SelectionActions>
  selectionState: SelectionState
}

const MessageSelection = ({
  userDispatch,
  userState,
  selectionDispatch,
  selectionState,
}: Props) => {
  const isGLSEN2023 = selectionState.campaign?.slug === "GLSEN2023"
  return (
    <div id="message-selection">
      {isGLSEN2023 ? (
        <h3>Preview your message</h3>
      ) : (
        <>
          <h3>Write your Message</h3>
          <p className="lead tight">
            Keep it short, direct and civil. You can use our sample message or
            write your own.
          </p>
          <p className="lead tight">
            We'll take care of the greeting to the representative(s) you
            selected. Customize your message below:
          </p>
        </>
      )}
      <PostcardTextInput
        selectionState={selectionState}
        selectionDispatch={selectionDispatch}
      />
      <p className="lead tight">We'll add a signature for you.</p>
      <p>Enter your name and email address below:</p>
      <UserDetailInput userState={userState} userDispatch={userDispatch} />
    </div>
  )
}

export default MessageSelection
