import React, { Dispatch, useEffect, useState } from "react"
import ContentLoader from "react-content-loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PaymentSection from "./PaymentSection"
import CallAndEmail from "./CallAndEmail"
import OtherCampaigns from "./OtherCampaigns"
import { UserActions } from "../utils/userReducer"
import { SelectionActions } from "../utils/selectionReducer"
import {
  UserState,
  SelectionState,
  PaypalPayment,
  LobResponse,
  Rep,
} from "../types"
import { isDevEnv, apiClient, reportError, reportMessage } from "../utils/index"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { format } from "date-fns"
import SocialShare from "./SocialShare"

interface Props {
  userDispatch: Dispatch<UserActions>
  userState: UserState
  selectionDispatch: Dispatch<SelectionActions>
  selectionState: SelectionState
}

const Payment = ({
  userDispatch,
  userState,
  selectionDispatch,
  selectionState,
}: Props) => {
  const [hasPaid, setHasPaid] = useState<boolean>(
    isDevEnv && !!(process.env.GATSBY_SKIP_PAYMENT === "true")
  )
  const [postcards, setPostcards] = useState<LobResponse[]>([])
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState("")
  const [processing, setProcessing] = useState(false)

  const count = selectionState.reps.length
  const isGLSEN = selectionState.campaign?.slug.includes("GLSEN")
  const isGLSEN2023 = selectionState.campaign?.slug === "GLSEN2023"
  const isDONS = selectionState.campaign?.slug === "DONS"
  const onPayment = (payload: PaypalPayment | null): void => {
    if (!payload) {
      // payment cancelled
      // TODO: add message
    } else {
      // continue with payment
      setHasPaid(true)
      console.log("successful payment", JSON.stringify(payload))
    }
  }

  useEffect(() => {
    if (isGLSEN2023) {
      setHasPaid(true)
    }
  }, [])

  useEffect(() => {
    const sendMail = async () => {
      setProcessing(true)
      try {
        const { data } = await apiClient.post<LobResponse[]>("sendPostcard", {
          user: userState,
          selection: selectionState,
        })
        setProcessing(false)
        setPostcards(data)
        if (!isGLSEN2023) {
          const res = await apiClient.post<any>("sendEmail", {
            user: userState,
            selection: selectionState,
            postcardInfo: data[0],
          })
          console.log("res", res)
          if (res.status === 200) {
            setEmailSent(true)
          }
        }
        // set localStorage for User
        localStorage.setItem("email", userState.email)
        localStorage.setItem("firstName", userState.firstName)
        localStorage.setItem("lastName", userState.lastName)
      } catch (e) {
        console.log("sendMail error", e)
        reportError(e)
        if (postcards.length > 0) {
          setError(
            "Your postcard(s) have been sent though there was an  error sending your email"
          )
        } else {
          setError("There was an error sending your postcard(s)")
        }
      } finally {
        setProcessing(false)
        selectionDispatch({ type: "ALL_DONE" })
      }
    }
    if (hasPaid) {
      // Implement #11 disable go back button once payment is made
      // TODO: implement run this only once!!!
      sendMail()
    }
  }, [hasPaid])

  const onPaymentError = (error): void => {
    reportError(error)
    reportMessage(`Payment error for ${userState.email}`)
    setError("There was an error with your payment")
  }

  const userReps: Rep[] = []
  if (userState.reps?.house) {
    userReps.push(userState.reps.house)
  }
  if (userState.reps?.senate) {
    userReps.push(...userState.reps.senate)
  }
  if (userState.reps?.governor) {
    userReps.push(userState.reps.governor)
  }
  if (userState.reps?.stateHouse) {
    userReps.push(userState.reps.stateHouse)
  }
  if (userState.reps?.stateSenate) {
    userReps.push(userState.reps.stateSenate)
  }

  return (
    <div id="payment">
      <h3>Send your {count === 1 ? "Card" : "Cards"}</h3>
      {!selectionState.allDone && !isGLSEN2023 && (
        <p className="lead tight">
          You'll be charged ${count * 2}, out of which ${count} will be donated
          to <b>{selectionState?.campaign?.charity}</b>.
        </p>
      )}
      {!selectionState.allDone && !isGLSEN2023 && (
        <p>
          <b>
            We'll be sending you an email update once we received your order and
            again when your postcard is in transit.
          </b>
        </p>
      )}
      {!hasPaid && (
        <PaymentSection
          amount={selectionState.reps.length * 2}
          onPayment={onPayment}
          onPaymentError={onPaymentError}
        />
      )}
      {hasPaid && (
        <div className="payment-info">
          {processing && (
            <div className="loading">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="3x"
                color="#f9f8f4"
              />
            </div>
          )}
          {postcards.length > 0 && (
            <div id="payment-sent-box">
              <p>
                <span>
                  {`Successfully sent ${postcards.length} ${
                    postcards.length === 1 ? "postcard" : "postcards"
                  }.`}{" "}
                </span>
                The estimated delivery date is{" "}
                {format(
                  new Date(postcards[0].expected_delivery_date),
                  "eeee, MMM do yyyy"
                )}
                .
              </p>
              <p>
                <a
                  href={postcards[0].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to see a link of your postcard preview.
              </p>
            </div>
          )}
          {emailSent && !isGLSEN2023 && (
            <p>{`We've sent you an email with some more information.`}</p>
          )}
          {isGLSEN && (
            <p className="bold">
              Help us spread the word! Share this link on Instagram:{" "}
              <a href="https:bit.ly/GLSEN23">bit.ly/GLSEN23</a>. Tag @GLSEN and
              @congress.cards. Include the hashtag #RiseUp4LGBTQ
            </p>
          )}
          <div className="vote-gov-wrapper">
            <a
              href={
                isDONS ? "https://www.glsen.org/vote" : "https://www.vote.gov"
              }
              target="_blank"
            >
              <img src="/votegov.png" alt="Vote.gov logo" />
            </a>
          </div>
          {isDONS ? (
            <>
              <p>
                Thank you for breaking your silence and taking action to support
                LGBTQ+ Youth.
              </p>
              <p>
                Take it one step further and verify your voter registration,
                register to vote, or if you are under 18, pre-register to vote
                because it is never to early to start thinking about how you
                will break the silence. Visit{" "}
                <a href="https://www.glsen.org/vote" target="_blank">
                  glsen.org/vote
                </a>{" "}
                today because together, we must Rise Up for LGBTQ+ Youth and
                make our voices heard at the ballot box.
              </p>
            </>
          ) : (
            <>
              <p>
                Take it one step further! Check your voter registration, or
                register today at{" "}
                <a href="https://www.vote.gov" target="_blank">
                  vote.gov
                </a>
              </p>
              <p>
                If you’re under 18, you might be able to pre-register! Learn
                more{" "}
                <a
                  href="https://www.rockthevote.org/how-to-vote/nationwide-voting-info/voter-pre-registration/"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </>
          )}
          <p></p>

          {/* <MyBulletListLoader /> */}
        </div>
      )}
      {error && <p className="red">{error}</p>}
      {selectionState.allDone && (
        <>
          <div id="spacer">
            <SocialShare />
          </div>
          <div id="all-done">
            <CallAndEmail reps={userReps} />
            <OtherCampaigns
              currentCampaign={selectionState.campaign?.campaignName || ""}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Payment
