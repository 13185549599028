import React, { Dispatch, useEffect, useState } from "react"
import { UserDetails, UserState } from "../types"
import { UserActions } from "../utils/userReducer"

interface Props {
  userState: UserState
  userDispatch: Dispatch<UserActions>
}

const UserDetailInput = ({ userState, userDispatch }: Props) => {
  const [details, setDetails] = useState<UserDetails>({
    firstName: "",
    lastName: "",
    email: "",
  })

  useEffect(() => {
    // prefer current state, otherwise try to get localStorage, if not set to empty string
    const prevFirst =
      userState.firstName || window?.localStorage.getItem("firstName") || ""
    const prevLast =
      userState.lastName || window?.localStorage.getItem("lastName") || ""
    const prevEmail =
      userState.email || window?.localStorage.getItem("email") || ""
    const newValues = {
      firstName: prevFirst,
      lastName: prevLast,
      email: prevEmail,
    }
    setDetails(newValues)
    userDispatch({ type: "SET_USER_DETAILS", userDetails: newValues })
  }, [])

  const handleChange = (value: string, field: string): void => {
    const newValues = { ...details, [field]: value }
    userDispatch({ type: "SET_USER_DETAILS", userDetails: newValues })
    setDetails(newValues)
  }
  return (
    <div>
      <section className="address-form">
        <input
          className="first"
          onChange={e => handleChange(e.target.value, "firstName")}
          type="text"
          value={details.firstName}
          placeholder="First Name"
          data-testid="firstName"
        />
        <input
          className="last"
          onChange={e => handleChange(e.target.value, "lastName")}
          type="text"
          value={details.lastName}
          placeholder="Last Name"
          data-testid="lastName"
        />
        <input
          className="email"
          onChange={e => handleChange(e.target.value, "email")}
          type="email"
          value={details.email}
          placeholder="Email Address"
          data-testid="email"
        />
      </section>
    </div>
  )
}

export default UserDetailInput
