import React from "react"
import { steps } from "../constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

interface Props {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  stepIsValid: boolean
  allDone: boolean
}

const StepNavigation = ({ step, setStep, stepIsValid, allDone }: Props) => {
  const length = steps.length - 1
  const classes = (step, i) => {
    let c = "FormStepIcon "
    if (step > i) {
      c = c + `is-passed `
    }
    if (i === step) {
      c = c + "is-active "
    }
    if (i === length) {
      c = c + "FormStepIcon--last "
    }
    return c
  }

  if (allDone) {
    return null
  }

  return (
    <div id="step-navigation">
      {step < steps.length - 1 && (
        <button
          role="button"
          onClick={() => setStep(step + 1)}
          className="btn-next"
          disabled={!stepIsValid}
        >
          Next: {steps[step + 1]}
        </button>
      )}
      {step > 0 && (
        <button onClick={() => setStep(step - 1)} className="btn-prev">
          Go Back
        </button>
      )}
      <div id="progress-indicator">
        <ol className="FormStepList">
          {steps.map((s, i) => {
            return (
              <li key={i} className="FormStepList-item">
                <span className={classes(step, i)}>
                  <span className="FormStepIcon-circle">
                    {i < step && (
                      <FontAwesomeIcon icon={faCheck} color="white" />
                    )}
                  </span>
                  <span className="FormStepIcon-title">{steps[i]}</span>
                </span>
              </li>
            )
          })}
        </ol>
      </div>
    </div>
  )
}

export default StepNavigation
